import React from 'react'
import Landing from './Landing'
// import Nav from './NavBar'

const HomePage = () => {
  return (
    <div className='HomePage'>
      <Landing />

    </div>
  )
}

export default HomePage