const Plan = [
  {
    i: "fa-regular fa-heart",
    h2: "basic plan",
    number: "$25",
    li_1: "2 hours of excercises",
    li_2: "Free consultion to coaches",
    li_3: "Access to The Community",
    more: "see more benfits",
    button : "join now"
  },
  {
    i: "fa-solid fa-hat-cowboy-side",
    h2: "PREMIUM PLAN",
    number: "$30",
    li_1: "5 hours of excercises",
    li_2: "Free consultion to coaches",
    li_3: "Access to The minibar",
    more: "see more benfits",
    button : "join now"
  },
  {
    i: "fa-solid fa-dumbbell",
    h2: "PRO PLAN",
    number: "$45",
    li_1: "8 hours of excercises",
    li_2: "Free consultion to coaches",
    li_3: "Access to The Merchandises",
    more: "see more benfits",
    button : "join now"
  },
];

export default Plan